<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import LandingPage from './components/LandingPage.vue'

export default {
  name: 'App',
  // components: {
  //   LandingPage
  // }
}
</script>

<style>
button, button:focus, button:active{
  outline: none;
}
</style>
