<template>
  <div v-if="authenticationLogin === true">
    <h2>Fairtrader Mediator Admin Pannel</h2>
    <table class="styled-table">
      <thead>
        <tr>
          <!-- <th>User Id</th> -->
          <th>User Image</th>
          <th>User Name</th>
          <th>User Email</th>
          <th>User Country</th>
          <th>User Wallet</th>
          <th>User Facebook</th>
          <th>Created At</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-row" v-for="user in userData" :key="user">
          <!-- <tr class="table-row" v-for="user in userData.reverse()" :key="user"> -->
          <!-- <td>{{ user.id }}</td> -->
          <td>
            <img
              :src="user.idCard"
              width="40px"
              onerror="this.src='https://static.thenounproject.com/png/17241-200.png'"
            />
          </td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.country }}</td>
          <td>{{ user.cardNumber }}</td>
          <a :href="user.facebookLink" style="color: #4c4646"
            ><td>{{ user.facebookLink }}</td></a
          >
          <td>{{ user.createdAt }}</td>
          <!-- also able to change after first decision -->
          <td style="cursor: pointer">
            <b v-if="user.mediatorStatus === ''"
              ><span style="color: green" @click="approveMedHandle(user.id)"
                >Approve</span
              >
              /
              <span style="color: red" @click="rejectMedHandle(user.id)"
                >Reject</span
              ></b
            >
            <b v-if="user.mediatorStatus === 'approved'"
              ><span style="color: green"
                >Approved /
                <span style="color: red" @click="rejectMedHandle(user.id)"
                  >Reject</span
                ></span
              ></b
            >
            <b v-if="user.mediatorStatus === 'rejected'"
              ><span style="color: red"
                >Rejected /
                <span style="color: green" @click="approveMedHandle(user.id)"
                  >Approve</span
                ></span
              ></b
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>
    <div class="signInBox">
      <div class="containersignInBox">
        <div class="boxsignInBox">
          <div>
            <br /><br />
            <br />
            <div id="main-div">
              <div class="second-div">
                <div>
                  <img
                    src="../assets/img/bottomPageLogo.png"
                    class="lock-img"
                  />
                </div>
                <br />
                <br />
                <br />
                <br />
                <div class="lock-div">
                  <h3 class="lock-subheading">SIGN IN</h3>
                  <br />
                  <h6 class="sign-in-sub">
                    Sign in to create<span
                      v-on:click="authenticationLogin = true"
                      >,</span
                    >
                    discover and connect <br />
                    with the global community.
                  </h6>
                </div>
                <br />
                <br />
                <div>
                  <input
                    class="input-fel"
                    type="text"
                    placeholder="Email"
                    style="margin-bottom: 15px"
                    v-model="emailInput"
                  />
                  <!-- ref={(email) => { this.email = email }} -->
                  <br />
                  <input
                    class="input-fel"
                    type="text"
                    placeholder="Password"
                    v-model="emailPassword"
                  />
                  <!-- ref={(emailPassword) => { this.emailPassword = emailPassword }} -->
                </div>
                <br />
                <br />
                <br />
                <br />
                <button class="log-in" @click="handleLogIn()">Log In</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      userToken: "",
      testUserID: "",
      userData: [],
      authenticationLogin: false,
      emailInput: "",
      emailPassword: "",
      adminEmail: "adminpannel@fairtrader.io",
      adminEmailPassword: "passwordOfAdminPannel",
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    this.testUserID = localStorage.getItem("userID");
    console.log("User ID: ", this.testUserID);

    this.axiosRequestForUserData();
  },
  methods: {
    axiosRequestForUserData() {
      this.userToken = localStorage.getItem("UserToken");
      const token = this.userToken;
      console.log(token);
      axios
        .get(process.env.VUE_APP_SERVICE_URL + "User", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          this.userData = res.data.users.reverse();
          console.log("UserData", this.userData);
        })
        .catch((err) => {
          this.$toasted.error("Something went wrong, Refresh the Page.");
          console.log("error", err);
        });
    },

    rejectMedHandle(e) {
      const token = this.userToken;
      axios
        .post(
          process.env.VUE_APP_SERVICE_URL + "User/rejectMediator",
          {
            id: e,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          console.log("UserData", res);
          this.$toasted.success("Successfully Rejected");

          this.axiosRequestForUserData();
        })
        .catch((err) => {
          this.$toasted.error("Something went wrong, Refresh the Page.");
          console.log("error", err);
        });
    },
    handleLogIn() {
      console.log(this.emailPassword);
      console.log(this.emailInput);
      console.log(this.adminEmailPassword);
      console.log(this.adminEmail);

      if (this.emailPassword !== this.adminEmailPassword) {
        alert("Password Not Correct");
      } else if (this.emailInput !== this.adminEmail) {
        alert("Email Not Correct");
      } else {
        this.authenticationLogin = true;
      }
    },
    approveMedHandle(e) {
      const token = this.userToken;
      axios
        .post(
          process.env.VUE_APP_SERVICE_URL + "User/approveMediator",
          {
            id: e,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          console.log("UserData", res);
          this.$toasted.success("Successfully Approved");

          this.axiosRequestForUserData();
        })
        .catch((err) => {
          this.$toasted.error("Something went wrong, Refresh the Page.");
          console.log("error", err);
        });
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 40px;
  margin: 20px 0;
  text-align: center;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  /* min-width: 400px; */
  width: 90%;
  margin: 0 auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/* Sign IN BOX */
.signInBox {
  /* background-color: red; */
  background-image: url(https://picsum.photos/id/1080/6858/4574),
    linear-gradient(rgb(219, 166, 166), rgb(0, 0, 172));
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.containersignInBox {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.boxsignInBox {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-family: sans-serif;
  text-align: center;
  line-height: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(20px);
  /* max-width: 50%;
  max-height: 50%; */
  height: 100%;
  width: 100%;
  padding: 20px 40px;
}

/* #top-div {
  margin: -8px;
  padding: 0;
  height: 98vh;
  background-color: #;
  color: rgb(68, 68, 68);
  font-family: Arial, Helvetica, sans-serif;
} */

#main-div {
  border-radius: 3px;
  width: 600px;
  margin: 60px auto;
  background: #071144;
  margin: 0px auto;
}

.second-div {
  border-radius: 8px;
  box-shadow: 0px 0px 0px 10px #e4e4e4;
  text-align: center;
  width: 600px;
  height: 650px;
  margin-top: 50px;
  /* height: auto; */
}

/* a {
  list-style: none;
  text-decoration: none;
  list-style-type: none;
  color: rgb(83, 83, 83);
  font-size: 18px;
} */

.lock-img {
  max-height: 190px;
  width: 500px;
  margin-top: 30px;
  /* margin-bottom: -90px; */
  height: 200px;
}

.lock-div {
  /* color: rgba(226, 10, 10, 0); */
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: bold;
}

.lock-subheading {
  font-weight: bold;
  font-size: 25px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.sign-in-sub {
  font-size: 14px;
  font-weight: bold;
  margin-top: -5px;
  color: rgb(204, 204, 204);
}

.input-fel {
  width: 350px;
  border: none;
  padding-bottom: 7px;
  border-bottom: 2px solid rgb(163, 163, 163);
  /* for moving of feild */
  transition-property: width;
  transition-duration: 1s;
  background: transparent;
  /* transition-delay: 1s; */
  height: 30px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  background: transparent;
}

.setTokenPrice {
  width: 200px;
  /* for moving of feild */
  transition-property: width;
  transition-duration: 1s;
  background: transparent;
  /* transition-delay: 1s; */
  height: 30px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  color: #810580;
  background: transparent;
  border: 3px solid #810580;
  margin-bottom: 15px;
  padding-left: 10px;
}

.input-fel:hover {
  border-bottom: 2px solid white;
  color: white;
  /* for moving of feild */
  width: 230px;
}

.input-fel:active {
  border-bottom: 2px solid white;
  color: white;
  /* for moving of feild */
  width: 230px;
}

.input-fel:focus {
  outline: none;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  background: transparent;
}

.forgot-pass {
  font-size: 10px;
  margin-left: 65px;
  color: rgb(74, 0, 192);
  display: inline-block;
}

.forgot-passw {
  font-size: 10px;
  margin-right: 7px;
  color: rgb(74, 0, 192);
  display: inline-block;
}

.log-in {
  padding: 11px 29px;
  /* background-color: rgb(97, 50, 158); */
  background-color: #09993b;
  border: 1px solid rgb(185, 185, 185);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  font-size: 18px;
}

.setTOkenPRiceBTN {
  padding: 2px 5px;
  font-size: 14px;
  margin-left: -38px;
  color: #810580;
  background: none !important;
  border-left: 3px solid #810580;
  border-radius: 0px;
}

.setTOkenPRiceBTN:hover {
  color: white !important;
  background: #09993b !important;
  border-left: 3px solid #09993b !important;
  border-radius: 0px !important;
}

.log-in-loader {
  padding: 11px 29px;
  /* background-color: rgb(97, 50, 158); */
  background-color: rgb(40, 0, 93);
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  font-size: 12px;
}

.log-in:hover {
  background-color: rgb(231, 231, 231);
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  border: 1px solid rgb(40, 0, 93);
}

@media screen and (max-width: 680px) {
  #main-div,
  .second-div {
    width: 400px;
  }

  .lock-img {
    width: 300px;
  }
}

@media screen and (max-width: 428px) {
  .assetsValueHeading {
    font-size: 14px !important;
  }
}
</style>